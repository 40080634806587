import React, { useCallback, useMemo, useState } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { Box, CardMedia, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import OpenIm from '@assets/png/openim.png';
import { SearchField } from '@components';
import { FindMethods, OpenImDetail, TempPatentDetail } from '@constants';
import { isMobileAtom, isSearchingAtom } from '@states';

import { DesAccordion } from './DesAccordion';
import { SearchMainWrapper, SearchBarWrapper, OpenImageWrapper, SearchTitleTypo } from './styled';

export const SearchBarArea = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useRecoilValue(isMobileAtom);

  const navigate = useNavigate();

  const [writtenName, setWrittenName] = useState('');
  const [isSearching, setIsSearching] = useRecoilState(isSearchingAtom);

  const movePage = useCallback(async () => {
    setIsSearching(true);
    setTimeout(function () {
      setIsSearching(false);
      navigate(`/search/${writtenName}`, { state: { keyword: writtenName } });
    }, 6000);
  }, [navigate, setIsSearching, writtenName]);

  const openImageArea = useMemo(() => {
    return (
      <OpenImageWrapper>
        <CardMedia component="img" src={OpenIm} />
        <Typography className="content">{isSmallScreen || isMobile ? TempPatentDetail : OpenImDetail}</Typography>
      </OpenImageWrapper>
    );
  }, [isMobile, isSmallScreen]);

  return (
    <>
      {!isSearching && (
        <SearchMainWrapper>
          <SearchTitleTypo>조상의 이름으로</SearchTitleTypo>
          <SearchTitleTypo>몰랐던 내 땅을 찾아보세요!</SearchTitleTypo>
          <SearchBarWrapper>
            <SearchField handleSubmit={movePage} setText={setWrittenName} />
          </SearchBarWrapper>
          <Box sx={{ width: '80%', overflow: 'auto' }}>
            <DesAccordion mainTitle="조상땅 찾는 방법" cons={FindMethods} more={openImageArea} />
          </Box>
        </SearchMainWrapper>
      )}
    </>
  );
};
