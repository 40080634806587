import lotImg from '@assets/png/fLotMap.jpg';
import landArchieveImg from '@assets/png/landArchieve.png';

export const FindMethods = [
  {
    title: '1. 조상님의 이름을 검색해보세요.',
    content: [
      '검색결과 나온 필지의 한자성함, 소유자주소가 조상님의 제적등본 상 한자성함, 거주지주소와 일치한다면 100년전쯤 조상님의 땅이었던 것입니다.',
    ],
  },
  {
    title: '2. 한글로 번역된 국가기록문서를 확인보세요.',
    content: [
      '- 검색된 결과물은 토지 º 임야조사부, 보안림편입고시, 국유임야매각고시 등 일제강점기에 한문으로 제작된 국가 기록들을 한글로 번역한 것입니다.',
      '- 토지조사부는 현행 토지제도의 근간으로, 대법원 판례상 최고 법적효력이 인정되는 원시취득 문서입니다.',
    ],
  },
  // {
  //   title: '3. AI를 활용하여 조상땅찾기를 진행해보세요!',
  //   content: ['100년전 필지의 현재주소, 조상님이 거주하시던 지역의 정보등을 AI에게 물어보세요.'],
  // },
];

export const OpenImDetail =
  '해당 데이터는 1910년대 일제강점 초기, 토지 조사 사업의 결과물로서 한문으로 제작된 토지조사부 및 임야조사부 등의 자료 원문을 한글로 번역한 것입니다. 자료원문은 국가기록원에서 확인하실 수 있습니다.';

const QA1 = {
  mainTitle: 'Q1. 증조, 고조 할아버지가 땅이 있으셨다는데, 지자체 조상땅찾기 서비스에서는 안나옵니다.',
  cons: [
    {
      content: [
        '지자체 조상땅찾기 서비스에서는 현재 할아버지 성함으로 된 토지만 나옵니다. 또 2008년 이전에 사망하신 분의 토지는 확인할 수 없습니다. 6.25때 소실되었거나, 일제강점기때 민간인들 가지고 있었던 엄청나게 많은 토지들은 1980년대 이후 상당부분 국가로 귀속되었기에 확인되지 않는 것입니다.',
      ],
    },
  ],
};
const QA2 = {
  mainTitle: 'Q2. 지자체 조상땅찾기 서비스에 안나오는 땅들을 찾을 수 있나요?',
  cons: [
    {
      content: ['땅찾GO에서 제공하는 [토지조사부, 임야조사부] 자료를 확인하시면 찾으실 수 있습니다. '],
    },
  ],
};
const QA3 = {
  mainTitle: 'Q3. 땅찾GO에서 제공하는 토지조사부, 임야조사부는 무엇인가요?',
  cons: [
    {
      content: [
        '일제강점 초기, 총독부 임시토지조사국이 토지조사사업(1910-1918)과 임야조사사업(1916-1924)을 실시했습니다. 그 결과 만들어진 서류가 토지조사부, 임야조사부입니다. 이 서류들은 현재 대법원에서 인정하는 조상땅찾기 소송관련 가장 강력한 법적효력을 지닌 문서입니다.',
      ],
      img: landArchieveImg,
    },
  ],
};

const QA4 = {
  mainTitle: 'Q4. 옛날 땅이 지금 어디에 있는지 도저히 못찾겠어요',
  cons: [
    {
      content: [
        '땅찾GO에서 “현재예상지역 지도서비스”를 활용하시면 비교적 쉽게 찾으실 수 있습니다. 행정구역 변천과 토지 지번의 지속적인 변경으로 인해 100년전 할아버지 땅이 지금 어디인지 정확히 찾아내는 작업은 매우 복잡합니다. 이 과정을 지도 서비스를 활용해서 큰 도움을 받으실 수 있습니다.',
      ],
      img: lotImg,
    },
  ],
};

const QA5 = {
  mainTitle: 'Q5. 회원 탈퇴를 하고 싶어요',
  cons: [
    {
      content: [
        '불편을 끼쳐드려 죄송합니다. 가입하신 이메일을 함께 적어 인스타그램 DM 또는 dasidreamgo@gmail.com으로 연락 주시면 감사하겠습니다.',
      ],
    },
  ],
};

export const QAs = [QA1, QA2, QA3, QA4, QA5];

export const TempPatentDetail = '특허 가출원 번호: 10-2023-0076900';

export const ServiceUseTerm = `
제1조 목적

이 약관은 땅찾GO(전자상거래 사업자로 이하 "땅찾고"라 한다)가 운영하며 제공하는 인터넷 관련 서비스(이하 "서비스"라 한다)를 이용함에 있어 웹사이트와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」


제2조 정의

    "땅찾고" 란 "회사"가 재화 또는 서비스(이하 "서비스 등" 이라 함)을 이용자에게 제공하기 위하여 컴퓨터등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 웹사이트를 운영하는 사업자의 의미로도 사용합니다.
    "이용자"란 "땅찾고"에 접속하여 이 약관에 따라 "땅찾고"가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
    '회원'이라 함은 “땅찾고”에 회원등록을 한 자로서, 계속적으로 "땅찾고"가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
    '비회원'이라 함은 회원에 가입하지 않고 "땅찾고"가 제공하는 서비스를 이용하는 자를 말합니다.


제3조 약관 등의 명시와 설명 및 개정

    "땅찾고"는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호·전자우편주소, 사업자등록번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 “땅찾고“의 초기 서비스화면(마이페이지 등)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
    "땅찾고"는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회·환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
    "땅찾고"는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
    "땅찾고"가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 땅찾고의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "땅찾고“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
    "땅찾고"가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “땅찾고”에 송신하여 “땅찾고”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
    이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 「전자상거래 등에서의 소비자 보호지침」 및 관계법령 또는 상관례에 따릅니다.


제4조 서비스의 제공 및 변경

    "땅찾고"는 다음과 같은 업무를 수행합니다.
        재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
        구매계약이 체결된 재화 또는 용역의 배송
        기타 "땅찾고"가 정하는 업무
    "땅찾고"는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
    "땅찾고"가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화 등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 통지합니다.
    전항의 경우 "땅찾고"는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, "땅찾고"가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.


제5조 서비스의 중단

    "땅찾고"는 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
    "땅찾고"는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, "땅찾고"가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
    사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "땅찾고"는 제8조에 정한 방법으로 이용자에게 통지하고 당초 "땅찾고"에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, "땅찾고"가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 "땅찾고"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.


제6조 회원가입

    이용자는 "땅찾고"가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
    "땅찾고"는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.
        가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실후 3년이 경과한 자로서 "땅찾고"의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
        등록 내용에 허위, 기재누락, 오기가 있는 경우
        기타 회원으로 등록하는 것이 "땅찾고"의 기술상 현저히 지장이 있다고 판단되는 경우
    회원가입계약의 성립시기는 "땅찾고"의 승낙이 회원에게 도달한 시점으로 합니다.
    회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 "땅찾고"에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.


제7조 회원 탈퇴 및 자격 상실 등

    회원은 "땅찾고"에 언제든지 탈퇴를 요청할 수 있으며 "땅찾고"는 즉시 회원탈퇴를 처리합니다.
    회원이 다음 각호의 사유에 해당하는 경우, "땅찾고"는 회원자격을 제한 및 정지시킬 수 있습니다.
        가입 신청시에 허위 내용을 등록한 경우
        "땅찾고"를 이용하여 구입한 재화 및 서비스 대금 등과 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
        다른 사람의 "땅찾고" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
        "땅찾고"를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
    "땅찾고"가 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회이상 반복되거나 30일이내에 그 사유가 시정되지 아니하는 경우 "땅찾고"는 회원자격을 상실시킬 수 있습니다.
    "땅찾고"가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.


제8조 회원에 대한 통지

    "땅찾고"가 회원에 대한 통지를 하는 경우, 회원이 "땅찾고"과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
    "땅찾고"는 불특정다수 회원에 대한 통지의 경우 1주일이상 "땅찾고" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.


제9조 구매신청

    신청자는 "땅찾고홈페이지"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "땅찾고"는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
        재화(정보_고문서) 등의 검색 및 선택
        받는 사람의 성명, 메일, 전화번호,팩스번호, 전자우편주소(또는 이동전화번호) 등의 입력
        약관내용, 정보이용수수료등의 비용부담과 관련한 내용에 대한 확인
        이 약관에 동의하고 확인하거나 거부하는 표시 (예, 마우스 클릭)
        정보서비스 등의 구매신청 및 이에 관한 확인 또는 “땅찾고”의 확인에 대한 동의
        결제방법의 선택
    "땅찾고"가 제3자에게 구매자 개인정보를 제공·위탁할 필요가 있는 경우 실제 구매신청 시 구매자의 동의를 받아야 하며, 회원가입 시 미리 포괄적으로 동의를 받지 않습니다. 이 때 "땅찾고"는 제공되는 개인정보 항목, 제공받는 자, 제공받는 자의 개인정보 이용 목적 및 보유ㆍ이용 기간 등을 구매자에게 명시하여야 합니다. 다만 「정보통신망이용촉진 및 정보보호 등에 관한 법률」 제25조 제1항에 의한 개인정보 취급위탁의 경우 등 관련 법령에 달리 정함이 있는 경우에는 그에 따릅니다.


제10조 계약의 성립

    "땅찾고"는 제9조와 같은 구매신청에 대하여 다음 각호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
        신청 내용에 허위, 기재누락, 오기가 있는 경우
        미성년자가 법정대리인의 동의 없이 구매하는 경우
        기타 구매신청에 승낙하는 것이 "땅찾고" 기술상 현저히 지장이 있다고 판단하는 경우
    "땅찾고"의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
    "땅찾고"의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 서비스가능 여부, 구매신청의 정정 취소 등에 관한 정보등을 포함하여야 합니다.


제11조 지급방법

"땅찾고"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, "땅찾고"는 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.

    폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
    온라인무통장입금
    전자화폐에 의한 결제
    기타 전자적 지급 방법에 의한 대금 지급 등


제12조 수신확인통지ㆍ구매신청 변경 및 취소

    "땅찾고"는 이용자의 구매신청이 있는 경우 희망하는 이용자에게 수신확인통지(웹사이트상 또는 전자우편으로 확인)를 합니다.
    수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 "땅찾고"는 이메일전송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 정보이용수수료를 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.


제13조 정보제공 공급

    "땅찾고"는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 신청을 한 날부터 3영업일 이내에 정보 등을 전송할 수 있도록 기타의 필요한 조치를 취합니다. 다만, "땅찾고"가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 7영업일 이내에 조치를 취합니다. 이때 "땅찾고"는 이용자가 재화등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
    "땅찾고"는 이용자가 구매한 재화에 대해 배송수단 등을 명시합니다. 만약 "땅찾고"가 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 "땅찾고"가 고의ㆍ과실이 없음을 입증한 경우에는 그러하지 아니합니다.


제14조 환급

"땅찾고"는 이용자가 구매신청한 재화 및 서비스등이 고문서 식별불가 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 재화 및 서비스를 인도 또는 제공할 수 없음을 인지한 날로부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.


제15조 청약철회 등

    "땅찾고"과 재화 등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
    제2항제2호 내지 제4호의 경우에 "땅찾고"가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회등이 제한되지 않습니다.
    이용자는 제1항 및 제2항의 규정에 불구하고 재화등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화등을 공급받은 날부터 3월이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.


제16조 청약철회 등의 효과

   "땅찾고"는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시ㆍ광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회등을 하는 경우 재화 등의 반환에 필요한 비용은 "땅찾고"가 부담합니다.
    

제17조 개인정보보호

    "땅찾고"는 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
    "땅찾고"는 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
    "땅찾고"는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
    "땅찾고"가 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
    이용자는 언제든지 "땅찾고"가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "땅찾고"는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 "땅찾고"는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
    "땅찾고"는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
    

제18조 "땅찾고"의 의무

    "땅찾고"는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화·용역을 제공하는데 최선을 다하여야 합니다.
    "땅찾고"는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추도록 최선을 다합니다.
    "땅찾고"가 상품이나 용역에 대하여 「표시ㆍ광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시ㆍ광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.


제19조 회원의 ID 및 비밀번호에 대한 의무

    제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
    회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
    회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "땅찾고"에 통보하고 "땅찾고"의 안내가 있는 경우에는 그에 따라야 합니다.


제20조 이용자의 의무

이용자는 다음 행위를 하여서는 안됩니다.

    신청 또는 변경시 허위 내용의 등록
    타인의 정보 도용
    "땅찾고"에 게시된 정보의 변경
    "땅찾고"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
    "땅찾고" 기타 제3자의 저작권 등 지적재산권에 대한 침해
    "땅찾고" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
    외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 땅찾고에 공개 또는 게시하는 행위


제21조 저작권의 귀속 및 이용제한

    "땅찾고"가 작성한 저작물에 대한 저작권 기타 지적재산권은 "땅찾고"에 귀속합니다.
    이용자는 "땅찾고"를 이용함으로써 얻은 정보 중 "땅찾고"에게 지적재산권이 귀속된 정보를 "땅찾고"의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
    "땅찾고"는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.


제22조 분쟁해결

    "땅찾고"는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치ㆍ운영 할 수 있습니다.
    "땅찾고"는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 통보해 드립니다.
    "땅찾고"과 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시ㆍ도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.


제23조 재판권 및 준거법

    "땅찾고"과 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
    "땅찾고"과 이용자간에 제기된 전자상거래 소송에는 한국법을 적용합니다.


부  칙 시행일 등

    이 약관은 2024년 9월 1일부터 시행합니다.
    이 약관은 전자상거래 표준약관 제10023호(2014. 9. 19. 개정)를 준수합니다.`;

export const PersonalInfoUseTerm = `
제1조 총칙

    본 사이트는 귀하의 개인정보보호를 매우 중요시하며, 『정보통신망이용촉진등에관한법률』상의 개인정보보호 규정 및 정보통신부가 제정한 『개인정보보호지침』을 준수하고 있습니다.
    본 사이트는 개인정보보호방침을 통하여 귀하께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
    본 사이트는 개인정보보호방침을 홈페이지 첫 화면 하단에 공개함으로써 귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다.
    본 사이트는 개인정보취급방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.


제2조 개인정보 수집에 대한 동의

    귀하께서 본 사이트의 개인정보보호방침 또는 이용약관의 내용에 대해 「동의한다」버튼 또는 「동의하지 않는다」버튼을 클릭할 수 있는 절차를 마련하여, 「동의한다」버튼을 클릭하면 개인정보 수집에 대해 동의한 것으로 봅니다.


제3조 개인정보의 수집 및 이용목적

    본 사이트는 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다.
    서비스제공을 위한 계약의 성립 : 본인식별 및 본인의사 확인 등
    서비스의 이행 : 자료제공 및 이용수수료 결제
    회원 관리 : 회원제 서비스 이용에 따른 본인확인, 개인 식별, 연령확인, 불만처리 등 민원처리
    기타 새로운 서비스, 자료나 이벤트 정보 안내
    단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.


제4조 수집하는 개인정보 항목

    본 사이트는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.
    수집항목 : 이름 , 생년월일 , 성별 , 로그인ID , 비밀번호 , 자택 전화번호 , 자택 주소 , 휴대전화번호 , 이메일 , 접속 로그 , 접속 IP 정보 , 결제기록
    개인정보 수집방법 : 홈페이지(회원가입)


제5조 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항

    본 사이트는 귀하에 대한 정보를 저장하고 수시로 찾아내는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트가 귀하의 컴퓨터 브라우저(넷스케이프, 인터넷 익스플로러 등)로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속을 하면 본 쇼핑몰의 컴퓨터는 귀하의 브라우저에 있는 쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.
    쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.

    쿠키 등 사용 목적 : 이용자의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
    쿠키 설정 거부 방법 : 쿠키 설정을 거부하는 방법으로는 귀하가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
    설정방법 예시 : 인터넷 익스플로어의 경우 → 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보
    단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다.


제6조 목적 외 사용 및 제3자에 대한 제공

    본 사이트는 귀하의 개인정보를 "개인정보의 수집목적 및 이용목적"에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타기업·기관에 제공하지 않습니다.
    그러나 보다 나은 서비스 제공을 위하여 귀하의 개인정보를 제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 귀하께 제휴사가 누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게 보호·관리되는지에 대해 개별적으로 전자우편 및 서면을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 귀하께서 동의하지 않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다.
    또한 이용자의 개인정보를 원칙적으로 외부에 제공하지 않으나, 아래의 경우에는 예외로 합니다.
    이용자들이 사전에 동의한 경우
    법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우


제7조 개인정보의 열람 및 정정

    귀하는 언제든지 등록되어 있는 귀하의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 "회원정보수정"을 클릭하여 직접 열람 또는 정정하거나, 개인정보관리책임자에게 E-mail로 연락하시면 조치하겠습니다.
    귀하가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인정보를 이용하지 않습니다.


제8조 개인정보 수집, 이용, 제공에 대한 동의철회

    회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 귀하께서 동의하신 내용을 귀하는 언제든지 철회하실 수 있습니다. 동의철회는 개인정보관리책임자에게 E-mail등으로 연락하시면 즉시 개인정보의 삭제 등 필요한 조치를 하겠습니다.
    본 사이트는 개인정보의 수집에 대한 회원탈퇴(동의철회)를 개인정보 수집시와 동등한 방법 및 절차로 행사할 수 있도록 필요한 조치를 하겠습니다.


제9조 개인정보의 보유 및 이용기간

    원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
    보존 항목 : 회원가입정보(로그인ID, 이름, 별명)
    보존 근거 : 회원탈퇴시 다른 회원이 기존 회원아이디로 재가입하여 활동하지 못하도록 하기 위함
    보존 기간 : 사이트 폐쇄 또는 영업 종료시
    그리고 상법 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 거래 및 회원정보를 보관합니다.
    보존 항목 : 계약 또는 청약철회 기록, 대금 결제 및 재화공급 기록, 불만 또는 분쟁처리 기록
    보존 근거 : 전자상거래등에서의 소비자보호에 관한 법률 제6조 거래기록의 보존
    보존 기간 : 계약 또는 청약철회 기록(5년), 대금 결제 및 재화공급 기록(5년), 불만 또는 분쟁처리 기록(3년)
    위 보유기간에도 불구하고 계속 보유하여야 할 필요가 있을 경우에는 귀하의 동의를 받겠습니다.


제10조 개인정보의 파기절차 및 방법

    본 사이트는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.
    파기절차 : 귀하가 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
    파기방법 : 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.


제11조 아동의 개인정보 보호

    본 사이트는 만14세 미만 아동의 개인정보를 수집하는 경우 법정대리인의 동의를 받습니다.
    만14세 미만 아동의 법정대리인은 아동의 개인정보의 열람, 정정, 동의철회를 요청할 수 있으며, 이러한 요청이 있을 경우 본 사이트는 지체없이 필요한 조치를 취합니다.


제12조 개인정보 보호를 위한 기술적 대책

    본 사이트는 귀하의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다.
    귀하의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
    해킹 등에 의해 귀하의 개인정보가 유출되는 것을 방지하기 위해, 외부로부터의 침입을 차단하는 장치를 이용하고 있습니다.


제13조 개인정보의 위탁처리

    본 사이트는 서비스 향상을 위해서 귀하의 개인정보를 외부에 위탁하여 처리할 수 있습니다.
    개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 귀하에게 고지하겠습니다.
    개인정보의 처리를 위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을 서면 또는 전자적으로 보관하겠습니다.


제14조 의견수렴 및 불만처리

    본 사이트는 개인정보보호와 관련하여 귀하가 의견과 불만을 제기할 수 있는 창구를 개설하고 있습니다. 개인정보와 관련한 불만이 있으신 분은 본 쇼핑몰의 개인정보 관리책임자에게 의견을 주시면 접수 즉시 조치하여 처리결과를 통보해 드립니다.
    개인정보 보호책임자 성명 : 박재현
    전화번호 : (031)949-1877
    이메일 : dasidreamgo@gmail.com
    또는 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
    개인분쟁조정위원회 (www.1336.or.kr / 1336)
    정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
    대검찰청 인터넷범죄수사센터 (icic.sppo.go.kr / 02-3480-3600)
    경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)


    부  칙 시행일 등
    본 방침은 2024년 9월 1일 부터 시행합니다.`;
